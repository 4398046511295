import { round, createEvent } from './utils';

const defaultProps = {
    baseClass: 'geta-image__hotspot',
    getScrollTop: () => document.body.scrollTop,
    getScrollLeft: () => document.body.scrollLeft,
    onSetContentPosition: () => undefined,
};

export default class Hotspot {
    constructor(domNode, props) {
        this.domNode = domNode;
        this.props = Object.assign(defaultProps, props || {}, parseAttributes(this.domNode));
        this.x = 0;
        this.y = 0;
        this.isActive = false;
        this.isOpen = false;
        this.initialize();
    }

    activate = () => {
        if (this.isActive) {
            return;
        }

        this.isActive = true;
        this.trigger.style.display = 'block';
    };

    destroy = () => {
        this.removeEventListeners();
        this.hide();
        this.isActive = false;
    };

    initialize = () => {
        this.x = this.props.x;
        this.y = this.props.y;
        this.trigger = this.domNode.querySelector('[data-geta-image__hotspot__trigger]');
        this.content = this.domNode.querySelector('[data-geta-image__hotspot__content]');
        this.closeButton = this.domNode.querySelector('[data-geta-image__hotspot__close]');

        this.toggleListener = (e) => this.toggleHandler(e);
        this.touchStartListener = () => this.onTouchStart();
        this.contentClickListener = (e) => this.contentClickHandler(e);
        this.addEventListeners();
    };

    show = () => {
        this.domNode.classList.add(`${this.props.baseClass}--active`);
        this.isOpen = true;
        this.setContentPosition();
        const event = createEvent('open', this);
        this.domNode.dispatchEvent(event);
    };

    hide = () => {
        document.body.classList.remove('geta-image__no-scroll');
        this.domNode.classList.remove(`${this.props.baseClass}--active`);
        this.isOpen = false;
    };

    addEventListeners = () => {
        this.domNode.addEventListener('mouseenter', this.toggleListener);
        this.domNode.addEventListener('mouseleave', this.toggleListener);
        this.domNode.addEventListener('touchstart', this.touchStartListener);
    };

    removeEventListeners = () => {
        this.domNode.removeEventListener('mouseenter', this.toggleListener);
        this.domNode.removeEventListener('mouseleave', this.toggleListener);
        this.domNode.removeEventListener('touchstart', this.touchStartListener);
        this.trigger.removeEventListener('click', this.toggleListener);
        this.content.removeEventListener('click', this.contentClickListener);
    };

    contentClickHandler = (e) => {
        if (e.target === this.content || e.target === this.closeButton) {
            e.preventDefault();
            this.hide();
        }
    };

    toggleHandler = (e) => {
        e.preventDefault();

        if (!this.isOpen) {
            this.show();
        } else {
            this.hide();
        }
    };

    onTouchStart = () => {
        this.removeEventListeners();
        this.trigger.addEventListener('click', this.toggleListener);
        this.content.addEventListener('click', this.contentClickListener);
    };

    setContentPosition = () => {
        const { onSetContentPosition } = this.props;
        const { offsetLeft, offsetTop, parentElement } = this.domNode;
        const { clientWidth, clientHeight } = this.content;
        const rect = new DOMRect(offsetLeft, offsetTop, clientWidth, clientHeight);

        if (rect.right > parentElement.clientWidth) {
            let left = -clientWidth;

            if (rect.x - clientWidth < 0) {
                left = clientWidth - rect.x;
            }

            this.content.style.left = `${left}px`;
        } else {
            this.content.style.left = '0';
        }

        if (rect.bottom > parentElement.clientHeight) {
            let top = -clientHeight;

            if (rect.y - clientHeight < 0) {
                top = clientHeight - rect.y;
            }

            this.content.style.top = `${top}px`;
        } else {
            this.content.style.top = '0';
        }

        onSetContentPosition(this);
    };

    setPosition = (x, y) => {
        this.activate();
        this.domNode.style.left = `${round(x * 100)}%`;
        this.domNode.style.top = `${round(y * 100)}%`;
    };
}

export const parseAttributes = (domNode) => {
    if (!domNode || !domNode.dataset || !domNode.dataset.getaImage__hotspot) return {};
    return JSON.parse(domNode.dataset.getaImage__hotspot);
};
