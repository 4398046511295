export const calculatePixelRect = (imageWidth, imageHeight, cropConfig, focalPointConfig) => {
    const cropLeft = cropConfig.left * imageWidth;
    const cropTop = cropConfig.top * imageHeight;

    const crop = {
        left: cropLeft,
        top: cropTop,
        width: imageWidth - cropConfig.right * imageWidth - cropLeft,
        height: imageHeight - cropConfig.bottom * imageHeight - cropTop,
    };

    const focalPointRx = focalPointConfig.width * imageWidth * 0.5;
    const focalPointRy = focalPointConfig.height * imageHeight * 0.5;
    const focalPointCenterX = focalPointConfig.x * imageWidth;
    const focalPointCenterY = focalPointConfig.y * imageHeight;
    const focalPoint = {
        left: focalPointCenterX - focalPointRx + cropLeft,
        top: focalPointCenterY - focalPointRy + cropTop,
        right: focalPointCenterX + focalPointRx,
        bottom: focalPointCenterY + focalPointRy,
    };

    return { crop, focalPoint };
};

export const fit = (source, destWidth, destHeight) => {
    const { crop, focalPoint } = source;

    const desiredAspectRatio = destWidth / destHeight;
    const cropAspectRatio = crop.width / crop.height;

    if (cropAspectRatio > desiredAspectRatio) {
        const height = crop.height;
        const width = height * desiredAspectRatio;
        const top = crop.top;

        // Center output horizontally over focalPoint
        const focalPointXCenter = (focalPoint.right - focalPoint.left) * 0.5 + focalPoint.left;
        let left = focalPointXCenter - width * 0.5;

        // Keep output within crop
        if (left < crop.left) {
            left = crop.left;
        } else if (left + width > crop.left + crop.width) {
            left = crop.left + crop.width - width;
        }

        return {
            left,
            top,
            width,
            height,
        };
    }

    // The crop is taller than the desired ratio, we are cutting from top and bottom
    const width = crop.width;
    const height = width / desiredAspectRatio;
    const left = crop.left;

    // Center output vertically over focalPoint
    const focalPointYCenter = (focalPoint.bottom - focalPoint.top) / 2 + focalPoint.top;
    let top = focalPointYCenter - height / 2;

    // Keep output rect within crop
    if (top < crop.top) {
        top = crop.top;
    } else if (top + height > crop.top + crop.height) {
        top = crop.top + crop.height - height;
    }

    return {
        left: Math.max(0, left),
        top,
        width,
        height,
    };
};

export const round = (number) => {
    return Math.round((number + Number.EPSILON) * 100.0) / 100.0;
};

export const createEvent = (name, detail) => {
    return new CustomEvent(name, {
        bubbles: true,
        cancelable: true,
        detail,
    });
};
