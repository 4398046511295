import { calculatePixelRect, fit } from './utils';
import Hotspot from './hotspot';

const defaultProps = {
    hotspotBaseClass: 'geta-image__hotspot',
    getScrollTop: () => document.body.scrollTop,
    getScrollLeft: () => document.body.scrollLeft,
    onSetHotspotContentPosition: () => undefined,
};

export class GetaImage {
    constructor(domNode, props) {
        const attributeProps = parseAttributes(domNode);
        this.domNode = domNode;
        this.props = { ...defaultProps, ...attributeProps, ...props };
        this.crop = null;
        this.pixelRect = null;
        this.hotspots = [];
        this.isInitialized = false;
        this.lastOpen = null;
        this.initialize();
    }

    destroy = () => {
        this.hotspots.forEach((h) => h.destroy());
        this.hotspots = [];
        this.removeEventListeners();
    };

    initialize = () => {
        this.image = this.domNode.querySelector('img');
        this.updateListener = () => this.update();
        this.resizeListener = () => window.requestAnimationFrame(() => this.update());
        this.openListener = (event) => this.onOpen(event);
        this.initializeHotspots();
        this.addEventListeners();

        if (this.image && this.image.complete) {
            this.update();
        }

        this.isInitialized = true;
    };

    initializeHotspots = () => {
        const hotspotElements = this.props.hotspots || this.domNode.querySelectorAll('[data-geta-image__hotspot]');
        hotspotElements.forEach((hotspot) => {
            this.hotspots.push(
                new Hotspot(hotspot, {
                    baseClass: this.props.hotspotBaseClass,
                    getScrollTop: this.props.getScrollTop,
                    getScrollLeft: this.props.getScrollLeft,
                    onSetContentPosition: this.props.onSetHotspotContentPosition,
                }),
            );
        });
    };

    setProps = (props) => {
        this.props = { ...this.props, ...props };
    };

    setHotspots = () => {
        this.hotspots.forEach((hotspot) => {
            const hotspotX = (hotspot.x * this.props.originalWidth - this.crop.left) / this.crop.width;
            const hotspotY = (hotspot.y * this.props.originalHeight - this.crop.top) / this.crop.height;

            hotspot.setPosition(hotspotX, hotspotY);
        });
    };

    addEventListeners = () => {
        if (this.image) this.image.addEventListener('load', this.updateListener);
        this.domNode.addEventListener('open', this.openListener);
        window.addEventListener('resize', this.resizeListener);
    };

    removeEventListeners = () => {
        if (this.image) this.image.removeEventListener('load', this.updateListener);
        this.domNode.removeEventListener('open', this.openListener);
        window.removeEventListener('resize', this.resizeListener);
    };

    onOpen = (event) => {
        if (event.detail === this.lastOpen) return;
        if (this.lastOpen && this.lastOpen.isOpen) {
            this.lastOpen.hide();
            this.lastOpen = null;
        }
        this.lastOpen = event.detail;
    };

    update = (props) => {
        if (props) {
            this.setProps(props);
        }
        this.pixelRect = calculatePixelRect(
            this.props.originalWidth,
            this.props.originalHeight,
            this.props.crop,
            this.props.focalPoint,
        );
        const width = this.image ? this.image.clientWidth : this.props.originalWidth;
        const height = this.image ? this.image.clientHeight : this.props.originalHeight;

        this.crop = fit(this.pixelRect, width, height);
        this.setHotspots();
    };
}

export const parseAttributes = (domNode) => {
    if (!domNode || !domNode.dataset || !domNode.dataset.getaImage) return {};
    return JSON.parse(domNode.dataset.getaImage);
};

export const initGetaImages = (props) => {
    const getaImages = document.querySelectorAll('[data-geta-image]');
    getaImages.forEach((image) => new GetaImage(image, props));
};
